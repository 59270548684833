export const initMobileMenu = () => {
    // VARS
    const hamburger = document.querySelector('[data-mobile-menu]');
    const headerMenuItems = document.querySelectorAll('[data-header-link]');
    const header = document.querySelector('[data-header]');
    const activeMenuClass = 'active';
    const activeBodyClassOverflow = 'overflow';
    const activeBodyClassOpenMenu = 'is-menu-open';
    if (!hamburger) return;

    // LISTENERS
    hamburger.addEventListener('click', handleOnClickHamburger, false);

    [...headerMenuItems].forEach((link) => {
        link.addEventListener('click', handleOnClickMenuItem, false);
    });

    // HANDLERS
    function handleOnClickHamburger() {
        toggleMenu();
    }

    function handleOnClickMenuItem() {
        closeMenu();
    }

    // FUNCTIONS
    function toggleMenu() {
        if (hamburger.classList.contains(activeMenuClass)) {
            closeMenu();
        } else {
            openMenu();
        }
    }

    function openMenu() {
        hamburger.classList.add(activeMenuClass);
        header.classList.add(activeMenuClass);
        document.body.classList.add(activeBodyClassOverflow);
        document.body.classList.add(activeBodyClassOpenMenu);
        disableScroll();
    }

    function closeMenu() {
        hamburger.classList.remove(activeMenuClass);
        header.classList.remove(activeMenuClass);
        document.body.classList.remove(activeBodyClassOverflow);
        document.body.classList.remove(activeBodyClassOpenMenu);
        enableScroll();
    }
};
