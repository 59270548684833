import Swiper from 'swiper/bundle';

export const initSliderTestimonials = () => {
    new Swiper('[data-testimonials-slider]', {
        loop: true,
        centeredSlides: false,
        slidesPerView: 1.2,
        spaceBetween: 20,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });
};
