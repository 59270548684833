export function InitAnimationSearch() {
    // VARS
    const $this = this;
    const popUpTerminal = document.querySelector('[data-popup-location-terminal]');
    const popUpHeader = document.querySelector('[data-header-popup]');
    const modalWrapper = document.querySelector('[data-modal-wrapper]');
    if (!popUpTerminal) return;
    const console = createAndSetEmptyConsole();
    const terminalWords = [
        '<span style="color:#00B649">performing server authentication: connect_to_server (332fs2);</span>',
        '<span style="color:#ffffff">>answer: secure connection to the server authenticated sucsessfully</span>',
        '<span style="color:#ffffff">>IMPORT: AES_256_KEYS ();</span>',
        '<span style="color:#ffffff">>IMPORT: AES_256_KEYS ();</span>',
        '<span style="color:#ffffff">>IMPORT: AES_256_KEYS ();</span>',
        '<span style="color:#00B649">>answer: secure connection to the server authenticated sucsessfully.</span>',
        '<span style="color:#00B649">>performing server authentication: connect_to_server (332fs2);</span>',
        '<span style="color:#00B649">>IMPORT: AES_256_KEYS ();</span>',
        '<span style="color:#FD9800">>Amount:</span>',
        '<span style="color:#FD9800">>Ma.912.387.ng undefined</span>',
        '<span style="color:#00B649">>Initialization of the redirection procedure.</span>',
    ];

    // FUNCTIONS
    function createAndSetEmptyConsole() {
        const ul = document.createElement('ul');
        ul.style.padding = '40px 32px 140px';
        ul.style.textAlign = 'left';
        popUpTerminal.appendChild(ul);

        return ul;
    }

    $this.productRenderLoop = () => {
        popUpHeader.classList.add('popup-console');
        modalWrapper.classList.remove('hidden');

        preRenderConsoleOutput(terminalWords, 70);

        setTimeout(() => {
            preRenderConsoleOutput(terminalWords, 70);
        }, 3200);

        setTimeout(() => {
            preRenderConsoleOutput(terminalWords, 70);
        }, 5000);

        setTimeout(() => {
            preRenderConsoleOutput(terminalWords, 70);
        }, 5800);

        setTimeout(() => {
            // terminal end
        }, 6600);
    };

    function preRenderConsoleOutput(arr, timeout) {
        for (let i = 0; i < arr.length; i++) {
            setTimeout(() => {
                renderConsoleOutput(arr[i], i);
            }, i * timeout);
        }
    }

    function renderConsoleOutput(element) {
        const li = document.createElement('li');
        li.setAttribute('class', 'popup-console-item');
        console.append(li);
        li.insertAdjacentHTML('afterend', element);
    }
}
