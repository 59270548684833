import { scrollToElem } from '../../../../../Common/Resources/src_front/js/helper-functions/scrollToElem';

export const initScrollToAnchor = () => {
    // VARS
    const anchorLinks = document.querySelectorAll('[data-anchor-link]');
    const homePage = document.querySelector('[data-home]');
    const parentalControl = document.querySelector('[data-parental-control]');

    // LISTENERS
    anchorLinks.forEach((link) => {
        link.addEventListener('click', function (e) {
            if (homePage || parentalControl) {
                e.preventDefault();
            }

            const href = this.getAttribute('href');
            const selector = href.slice(href.indexOf('#'), href.length);
            scrollToElem(selector, 0);
        });
    });
};
