import { setCookie } from '../helper-function/coockie/setCookie';
import { getCookie } from '../helper-function/coockie/getCookie';

export function initJivosite() {
    // INITS
    const jivoChatHashCookie = Boolean(getCookie('jivoChatHash'));

    if (jivoChatHashCookie) {
        appendChat();
        listenerChat();
        setJivoChatDataToCookie();
    } else {
        setTimeout(function () {
            appendChat();
            listenerChat();
        }, 5000);
    }

    // FUNCTIONS
    function appendChat() {
        const headID = document.getElementsByTagName('head')[0];
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = `//code.jivosite.com/widget/${window.spybubbleJivoChatHash}`;
        headID.appendChild(newScript);
    }

    function listenerChat() {
        const listenLoadChat = setInterval(timerListenLoadChat, 1000);

        function timerListenLoadChat() {
            if (Boolean(window.jivo_api)) {
                stopListenLoadChat();
                jivo_onLoadCallback();

                window.jivo_onMessageSent = function jivo_onMessageSent() {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatHash', window.spybubbleJivoChatHash, cookieOptions);
                };

                window.jivo_api.getVisitorNumber(function (error, visitorNumber) {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatVisitor', visitorNumber, cookieOptions);
                });
            }
        }

        function stopListenLoadChat() {
            clearInterval(listenLoadChat);
        }
    }

    function appendSessionToCookies(cookieName, value) {
        const cookieOptions = {
            path: '/',
        };

        setCookie(cookieName, value, cookieOptions);
    }

    function setJivoChatDataToCookie() {
        const submits = document.querySelectorAll('[data-jivo-chat-cookie]');

        submits.forEach((submit) => {
            submit.addEventListener('click', function (e) {
                const hashClient = `jv_store_${window.spybubbleJivoChatHash}_client`;
                const hashApp = `jv_store_${window.spybubbleJivoChatHash}_app`;

                const jv_store_client = localStorage.getItem(hashClient);
                const jv_store_app = localStorage.getItem(hashApp);

                if (!Boolean(jv_store_client) || !Boolean(jv_store_app)) return;

                const jivoChatSession = {
                    [hashClient]: JSON.parse(jv_store_client),
                    [hashApp]: JSON.parse(jv_store_app),
                };

                appendSessionToCookies('jivoChatSession', JSON.stringify(jivoChatSession));
            });
        });
    }

    function jivo_onLoadCallback() {
        jivo_api.setContactInfo({
            name: document.querySelector('[data-email]').getAttribute('data-email'),
            email: document.querySelector('[data-email]').getAttribute('data-email'),
        });
        jivo_api.setCustomData([
            {
                title: 'Brand',
                content: document.querySelector('[data-brand]').getAttribute('data-brand'),
            },
        ]);
    }
}
